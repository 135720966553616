import * as React from "react";
import { Link } from "gatsby";

import DefaultLayout from "../layouts/default";
import HalfGrid from "../components/HalfGrid";
import { GridElem } from "../components/GalerieGrid";

const NotFoundPage:React.FC = () => (
  <DefaultLayout grosserTitel="404" title="404: Diese Seite konnte nicht gefunden werden." description={""}>
      <GridElem>
        <p>
          Leider existiert hier nichts. <Link to="/">Gehen Sie zurück</Link> oder klicken Sie auf das Logo, um zur Startseite zu gelangen.
        </p>
      </GridElem>
  </DefaultLayout>
);

export default NotFoundPage;
