import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

export const GridElem = styled.div`
  padding: 30px 20px 40px;
  max-width: 1280px;
  margin: 0 auto;
  grid-gap: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    padding: 30px 20px 45px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 40px 30px 50px;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.max}px) {
    padding: 45px 0px 100px;
  }
`;

const GalerieGrid:React.FC = ({ children }) => <GridElem>{children}</GridElem>;

export default GalerieGrid;
